import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesLeona: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Leona guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_leona.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Leona guide & review</h1>
          <h2>A guide & review for Leona in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="leona" enablePopover />
        </div>
        <p>
          The latest member of Happy Zoo Squad is finally here! Leona brings a
          new gameplay definition to Shotgun (SG) characters: Critical.
          Previously, after the release of various powerful characters, SG was
          put in a weird spot. DPS meta was all about core hits and piercing, so
          much that SG rarely had a chance to shine. With Leona, SG now has
          their own distinctive playstyle. Will they finally be able to reclaim
          their lost throne?
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Leona introduces some unprecedented buffs into the game. From extended
          effective range to increased number of pellets, her skillset can be
          considered unique on its own. However, most of her abilities are
          unfortunately restricted to SG weapons, which means that she can only
          buff certain characters that currently have no place in meta, reducing
          her versatility and overall worth. That aside, let's take a look at
          each of her skills and analyze it:
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates after 5 normal attacks. Affects all allies. </p>
          <p>
            Roar: Critical Rate ▲ 2.62%, stacks up to 5 times and lasts for 5
            sec.
          </p>
          <p>
            ■ Activates after 15 normal attacks. Affects all allies with a
            Shotgun.{' '}
          </p>
          <p>Maximum Effective Range ▲ 20% for 10 sec.</p>
        </blockquote>
        <p>
          Two of Leona's primary strengths are her Maximum Optimal Range for SG
          units as well as the permanent CRIT Rate. Overall, the CRIT Rate boost
          (termed as Roar in-game) is always a welcome damage boost, especially
          for SGs, that synergizes well with other CRIT Rate and Damage buffs
          from her other skills, Tove's, and even Sugar's. It also lasts
          permanently as long as Leona has a valid target but requires some
          stacking to reach max stacks.
        </p>
        <p>
          What about the Maximum Optimal Range? The optimal range here refers to
          the increased damage dealt (0.3x multiplier) by attacks landed when
          the enemy is in the effective range of that specific weapon. If you
          examine the numbers, you would think that this buff allows SGs to be
          effective both in short range and within AR's range, boosting their
          adaptability against enemies who are situated slightly farther. But
          this could not be more wrong.
        </p>
        <Alert variant="primary">
          <p>
            This is the first part of her kit that is misleading. It was
            initially thought that SG's base Optimal Range, 0%-25%, would be
            increased to 0%-45% by this skill, but after testing it has been
            proven that the boost is only of 5%, as the 20% Range Boost applies
            directly on the 25% Base value. Hence, making the final Optimal
            Range of SG Nikkes 0%-30%, which barely affects gameplay and is a
            very big niche. For more information on optimal ranges, visit our{' '}
            <Link to="/nikke/guides/weapon-types/">Weapon Type</Link> guide.
          </p>
        </Alert>
        <p>
          What's worse is that while the ×0.3 bonus multiplier seems big, it is
          unfortunately diluted by other major modifiers: 0.5x on Full Burst,
          0.5x+ with critical hits, and 1x with core hits. Even worse, this buff
          only lasts 10 seconds, whereas to activate this buff, she needs to
          shoot 15 times, which also normally takes 10 seconds. Reloading will
          definitely break the chain. Luckily, the passive ATK Speed provided by
          Tove, the best SG buffer right now, will allow her to maintain stacks
          more comfortably, that is if Tove herself does not drop her stacks.
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p>■ Activates when entering Full Burst. Affects all allies. </p>
          <p>Hit Rate ▲ 20.28% for 10 sec.</p>
          <p>
            ■ Activates when entering Full Burst. Affects 2 ally units with the
            highest ATK and a Shotgun.{' '}
          </p>
          <p>Increases number of pellets by 5 for 10 sec.</p>
        </blockquote>
        <p>
          At the moment, this skill is half-useful, in a way that the Hit Rate
          buff is actually great, but the Pellet Count Boost is disappointing.
          This is the second part of her kit that can be misleading. Since
          Shotguns are naturally inaccurate, reducing the spread via boosting
          Hit Rate will result in more pellets hitting the enemy, and in turn,
          more core hits as well. This will allow them to hit thinner or farther
          enemies without much damage loss from missing. On the other hand, the
          Pellet Count Boost is currently meaningless as they do NOT noticeably
          boost your damage output at all. This is counter-intuitive indeed, but
          the increased pellet count does not lead to more damage per shot
          because each pellet will now deal less damage. You might think:
          wouldn't that allow more shots to land against thinner enemies?
          Theoretically, yes, but your damage does not actually improve.
        </p>
        <Alert variant="primary">
          <p>
            The Skill 2's Hit Rate buff prioritizes units who fulfill both
            requirements first in a specific order, starting with SG, then ATK.
            For instance, pairing Leona with Sugar, Drake, and Modernia will
            have Sugar and Drake enjoying the buff. Similarly, if the team only
            consists of 1 SG, then whoever has the highest ATK will claim the
            other buff.
          </p>
        </Alert>
        <StaticImage
          src="../../../images/nikke/generic/leona_range.jpg"
          alt="Leona"
        />
        <p>
          Let's dig deep into Hit Rate. Based on Korean's testing, the picture
          above shows the interaction table of how Hit Rate affects weapon's
          spread (the gray circle around your crosshair). Green cells indicate
          that your gun currently has AR's spread (comparable to Scarlet fully
          core-hitting against Solo Raid Material H's core). Red cells indicate
          full accuracy, like MGs, against any enemies with an accessible core.
          Therefore, your main checkpoint would be 75% (to match AR) and any
          higher is welcome. Unfortunately, right now, only Sugar can reach this
          high with a few Hit Rate OLs.{' '}
        </p>
        <h6>Burst</h6>
        <blockquote>
          <p>■ Affects all allies. </p>
          <p>Critical Damage ▲ 34.64% for 10 sec.</p>
          <p>
            ■ Activates when the caster's Roar is fully stacked. Affects all
            allies with a Shotgun.{' '}
          </p>
          <p>Critical Rate ▲ 21.32% for 10 sec.</p>
        </blockquote>
        <p>
          A momentary DPS boost for all SGs in team! This skill comprises two
          components: CRIT Damage for all allies and an impactful CRIT Rate buff
          to all SGs in team. Now, this skill may look dull at first, but if you
          take a step back and examine Leona's entire kit from a bigger
          perspective, it's actually perfect for her CRIT-driven kit, despite
          the general consensus that CRIT equals trash. So far, we learned that
          she provides the team with over 30% CRIT Rate at max level. Pair this
          with the base 15%, alongside CRIT Rate buffs from Tove and Sugar (and
          possibly OLs), and you'll find that CRIT Damage does not seem so bad
          anymore, particularly for SGs. Even then, do note that she needs 5
          Roar stacks before the CRIT Rate buff can activate, which means your
          first rotation will be devoid of it.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          Leona is designed for SGs, and so is Tove. However, Tove imparts a
          bigger impact compared to Leona, so in an ideal world, you would
          always want them to be together. Both are them are restricted to
          buffing SG teams, so any content where SG is an option will also be
          their battleground.
        </p>
        <h6>Campaign:</h6>
        <p>
          Leona will not see much use at all in Campaign as Shotguns perform
          poorly in Campaign.
        </p>
        <h6>Union Raid</h6>
        <p>
          With only 3 teams required for Union Raid, SG will not see much usage.
          This is because there are much better teams to deploy, and most of
          them are cost-efficient and effective against all sorts of bosses.
          Still, if you insist on using SGs as one of your team of choice, you
          will be delighted to know that they might compete with a
          non-fully-invested Dorothy + SAnis + Scarlet team. The main catch is
          all of your SG units, including Leona herself, must be super-invested.
          Is that a good idea? Maybe not.
        </p>
        <p>
          Sugar with Leona and Tove as support can deal more damage than Scarlet
          with Dorothy + S.Anis support. Problem is, as a whole, Dorothy and
          SAnis will outdamage the other units that you use in the SG team by A
          LOT. Not to mention, dropping your fully invested Scarlet + Doro +
          S.Anis for a similarly performing team while investing 10/10/10 on
          Leona + Tove + Sugar with full OL is just going to burn your savings.
        </p>
        <h6>Solo Raid</h6>
        <p>
          At this time, even the most invested SG teams would have a hard time
          competing against the current established meta teams. Nevertheless,
          since Solo Raid requires 5 teams, you may end up using them if you
          don't have other sub-meta DPS like 2B, or if you don't own all of the
          meta units. Depending on how stacked your current teams are
          (especially SW), SG teams might actually do you a favor as Team 4 or
          5.
        </p>
        <h6>Simulation Overlock</h6>
        <p>
          With many gimmicky restrictions related to weapon types and burst
          types, SGs may be valuable in the future. Remember that for full
          rewards, you need to pick a lot of restrictions, and that includes
          picking restrictions that limit team flexibility. For the current beta
          season, they may not be necessary. However, we do believe that the
          restrictions may change every season, so stay vigilant!
        </p>
        <h6>PVP</h6>
        <p>
          Do NOT even think about deploying her in PvP. Poli is a wiser choice
          if you are looking for a similar weapon type.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          An interesting finding with SG teams is that supports actually deal
          notable damage compared to true SG DPS (Sugar, Guilty). This is
          especially true with most of Leona's and Tove's buffs affecting all
          SGs in team. Because of this, you may consider OL-ing Leona and
          obtaining a few DPS-boosting OL lines, such as ATK or Max Ammo. You
          will definitely see an increased DPS output. It is expensive, yes, so
          it is not ideal at all.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 4</strong>
          </li>
          <ul>
            <li>
              The Maximum Optimal Range already reaches its max value at Level
              1. Thus, what you're gaining per level is a tiny increment of CRIT
              Rate. We recommend leaving it at 4 since its reinforcement is not
              worth the cost.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4/7</strong>
          </li>
          <ul>
            <li>
              Half of this skill is useless since Pellet Count does not offer
              much. On the other hand, Hit Rate can help your SGs land more
              core-hits, but at lower values, you may not even see any
              noticeable difference. Thus, we recommend leaving it at Level 4,
              or 7 if you understand how to maximize this skill effectiveness
              through OL and other skills. Level 10 is not recommended since it
              is only a ~3% Hitrate buff.
            </li>
          </ul>
          <li>
            <strong>Burst: 4-10</strong>
          </li>
          <ul>
            <li>
              Leona' Burst Skill has a good value and a decent scaling. You may
              consider investing in this skill, should you have abundant
              resources and are making a crit-build team. Otherwise, leaving it
              at 4 or 7 would be adequate, especially if you need to spare some
              resources for other more powerful units. According to our testing,
              each level boosts Sugar's DPS by around 0.7% on average, but
              remember that this buff also affects all SGs in team, not only
              Sugar. Hence, the actual team damage boost may be higher.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Guide"
        />
        <p>
          Both Bastion and Resilience would serve her well for PvE purposes for
          more reliable Roar (Skill 1) stacking.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1 - Crit Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team does not have healing, so it has restricted use for specific
          bosses only, such as Land Eater or Gravedigger. However, when
          invested, this team can give outputs for SAnis and Scarlet higher than
          their usual Dorothy + Privaty pair! However, since Volume is usually
          either with Blanc Noir or Tia Naga, this team recommendation is mostly
          for people who lack one of these pairs.
        </p>
        <h5>Team #2 - Bossing Team with Heals</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="bay-goddess-mary"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
            </div>
          </div>
        </div>
        <p>
          You will lose about 7% DPS with Sugar due to fewer bursts than normal,
          but it's comfy because you have a healer to back you up. Ideally, use
          this team against Solo Bosses. Boss 1, 2 and 4 in Union Raids are
          usually a decent option for SGs.
        </p>
        <h5>Team #3 - Bossing Team w/o Heals</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="leona" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team can be used in UR once you pass a certain level. Either
          that, or use it against bosses then rarely deal damage to you, such as
          Gravedigger, allowing your team going full DPS race over 180s.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Pull if you are an SG simp, especially Sugar's, or Leona's simp, else
          just skip. However, if in the future they release a unit that
          synergizes with Leona well, like a unit whose damage scales off pellet
          count, has CDR, or has tons of built-in CRIT, then Leona may be seeing
          more usage. For now, though, she is just not worth it.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Provides some CRIT Rate + CRIT DMG with 100% uptime if you
                    pair her with a well-invested Tove.
                  </li>
                  <li>
                    Increased Optimal Range for SG is a buff never seen before,
                    despite its small value.
                  </li>
                  <li>Looks very cute!!! •̀⩊•́</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Possesses a misleading kit; Skill 1 and Skill 2 barely boost
                    her usability.
                  </li>
                  <li>
                    Optimal Range Distance only goes from 25% -&gt; 30%, and not
                    45% as initially thought.
                  </li>
                  <li>
                    Half of S2 does nothing, since increased number of pellets
                    gives no boost in damage.
                  </li>
                  <li>
                    Requires insane investment on Sugar + Tove to even beat
                    Original Scarlet (read: Union Raid Usage Analysis).
                  </li>
                  <li>
                    CRIT is a non-meta buff in Nikke, so Leona is even less
                    useful outside SG teams.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box c">
            <h6>C </h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesLeona;

export const Head: React.FC = () => (
  <Seo
    title="Leona guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Leona in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
